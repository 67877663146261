import { Component, OnInit } from '@angular/core';
import { LoaderService } from './core/services/shared/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
    this.loaderService.displayLoader();
  }
}
