export const RoutePaths: IRoutePaths = {
    blank: '',
    profile: 'profile',

    recruiter: {
        parent: 'recruiter',

        addCandidate: 'add-candidate',
        editCandidate: 'edit-candidate',
        recruiterPipeline: 'recruiter-pipeline',

        jobsList: 'jobs-list',
        submittedCandidate: 'submitted-candidate'
    },
    company: {
        parent: 'company',

        company: 'company',
        myTasks: 'my-tasks',
    },
    candidate: {
        parent: 'candidate',
        myTasks: 'my-tasks',
        candidates: 'candidates',

    },
    admin: {
        parent: 'admin',

        admin: 'admin',
        campus: 'campus-admin'
    },
    auth: {
        signin: 'signin',
        signup: 'signup'
    },

}

export interface IRoutePaths {
    blank: string,
    profile: string,

    recruiter: {
        parent: string,

        addCandidate: string,
        editCandidate: string,
        recruiterPipeline: string,

        jobsList: string,
        submittedCandidate: string
    },
    company: {
        parent: string,

        company: string,
        myTasks: string,
    },
    candidate: {
        parent: string,
        myTasks: string,
        candidates: string,

    },
    admin: {
        parent: string,

        admin: string,
        campus: string
    },
    auth: {
        signin: string,
        signup: string
    },
}