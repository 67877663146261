import { Component, OnInit } from '@angular/core';
import { GoogleChartService } from '../service/google-chart.service';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {

  private gLib: any;

  constructor(private gChartService : GoogleChartService) { 
    this.gLib = this.gChartService.getGoogle();
    this.gLib.charts.load('current', {'packages':['corechart','table']});
    this.gLib.charts.setOnLoadCallback(this.drawChart.bind(this));
  }

  ngOnInit() {

  }

  private drawChart(){
    let chart = new this.gLib.visualization.PieChart(document.getElementById('divPieChart'));
    let data = new this.gLib.visualization.DataTable();
    data.addColumn('string', 'Status');
    data.addColumn('number', 'Quantity');
    data.addRows([
      ['Hired', 4],
      ['Interviewing', 24],
      ['Waiting on Review', 9],
      ['Rejected', 25],
     
    ]);

    let options = {
      'title':'Postings by Status',
      pieHole: 0.4,
            'pieSliceText' : 'percent',
            'slices': {  '4': {'offset': '0.2'},
                    '12': {'offset': '0.3'},
                    '14': {'offset': '0.4'},
                    '15': {'offset': '0.5'}},
      'legend' : 'left',
      'backgroundColor': 'transparent',
      'colors': ['black', 'purple', 'blue','green'],
      'is3d': 'true',
      // 'chartArea': {'left': 15, 'top': 15, 'right': 0, 'bottom': 0},
    };

    chart.draw(data, options);
  }

}
