import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../auth/auth.service';
import { AlertsService } from '../sweetalert/alerts.service';
import { AllProfileForms } from '../../forms/profiles/all-profiles.form';
import { ResumeService } from '../shared/resume.service';
import { FileMeta } from '../../modal/file-meta';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import firebase from "firebase/app";

export interface IUser extends firebase.User {
  'role': any;
  'image': any;
  'fullName': any;
  'address': any;
  'email': any;
  'phone': any;
  'linkedIn': any;
  'website': any;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  wait = true;
  public profileDict: any;
  public advanceDetails: any;
  candidateDocumentsList = [];

  profileDict$: any;
  advanceDetails$ = new BehaviorSubject(null);
  candidateDocumentsList$ = new BehaviorSubject(null);

  constructor(
    private allProfileForms: AllProfileForms,
    private alertServ: AlertsService,
    private db: AngularFireDatabase,
    private authServ: AuthService,
    private resumeService: ResumeService,
  ) {
  }

  // Save Basic Profile
  saveBasicInfo(form: any) {
    // console.log(form.value);
    this.db.object('/users/' + this.authServ.userID).update(form.value)
      .then(success => {
        this.alertServ.successWithConfirmButton(this.alertServ.messages.updatedTitle, this.alertServ.messages.updateMsg);
      })
      .catch(error => {
        this.alertServ.errorAlert(this.alertServ.messages.failedTitle, this.alertServ.messages.failedText);
      });
  }

  // Save Profile Details
  saveDetails(form?: FormGroup) {
    const role = this.profileDict['role'].charAt(0).toUpperCase() + this.profileDict['role'].slice(1);
    if (role === 'Candidate') {
      this.resumeService.upload('candidate')
        .then(() => {
          this.saveDetailsMini(role, this.allProfileForms.candidateForm.value);
        });
    } else {
      this.saveDetailsMini(role, form);
    }
  }

  saveDetailsMini(role: string, form?: FormGroup) {
    if (form)
      this.db.object('/roles/' + role + '/' + this.authServ.userID).update(form)
        .then(() => {
          this.alertServ.successWithoutConfirmButton(this.alertServ.messages.profileUpdateTitle, this.alertServ.messages.profileUpdateText);
        })
        .catch(() => {
          this.alertServ.errorAlert(this.alertServ.messages.failedTitle, this.alertServ.messages.failedText);
        });
  }

  // Getting profile
  getProfile() {
    this.authServ.getUserBasic()
      .subscribe((snapshots) => {
        this.profileDict = snapshots;
        this.allProfileForms.basicInfo.patchValue({
          fullName: this.profileDict['fullName'],
          email: this.profileDict['email'],
          image: this.profileDict['image'],
          role: this.profileDict['role'],
        });

        // this.allProfileForms.basicInfo.controls.fullName.setValue(this.profileDict['fullName']);
        // this.allProfileForms.basicInfo.controls.email.setValue(this.profileDict['email']);
        // this.allProfileForms.basicInfo.controls.image.setValue(this.profileDict['image']);
        // this.allProfileForms.basicInfo.controls.role.setValue(this.profileDict['role']);

        if (
          this.profileDict['address'] || this.profileDict['dob'] || this.profileDict['phone'] || this.profileDict['website'] ||
          this.profileDict['almaMater'] || this.profileDict['gender'] || this.profileDict['linkedIn']
        ) {
          this.allProfileForms.basicInfo.patchValue({
            address: this.profileDict['address'],
            almaMater: this.profileDict['almaMater'],
            dob: this.profileDict['dob'],
            gender: this.profileDict['gender'],
            linkedIn: this.profileDict['linkedIn'],
            phone: this.profileDict['phone'],
            website: this.profileDict['website']
          });

          // this.allProfileForms.basicInfo.controls.address.setValue(this.profileDict['address']);
          // this.allProfileForms.basicInfo.controls.almaMater.setValue(this.profileDict['almaMater']);
          // this.allProfileForms.basicInfo.controls.dob.setValue(this.profileDict['dob']);
          // this.allProfileForms.basicInfo.controls.gender.setValue(this.profileDict['gender']);
          // this.allProfileForms.basicInfo.controls.linkedIn.setValue(this.profileDict['linkedIn']);
          // // add additional Social Links
          // this.allProfileForms.basicInfo.controls.phone.setValue(this.profileDict['phone']);
          // this.allProfileForms.basicInfo.controls.website.setValue(this.profileDict['website']);
        }
        this.wait = false;
      });
  }

  getDetails() {
    const role = this.profileDict['role'].charAt(0).toUpperCase() + this.profileDict['role'].slice(1);
    this.authServ.getUserAdvance(role)
      .subscribe((resp:any) => {
        if (resp) {
          this.advanceDetails = resp; // TODO: remove
          this.advanceDetails$.next(resp);

          if (role === 'Recruiter') {
            this.allProfileForms.recruiterForm.setValue(resp);
          } else if (role === 'Candidate') {

            this.resumeService.filesMeta = [];

            if (resp['documents']) {
              for (const documents of resp['documents']) {
                this.allProfileForms.alterDocumentsControl(false);
                this.allProfileForms.adddocuments(undefined, null, null);

                let name = documents.name;
                name = name.substring(name.indexOf('_') + 1);
                this.resumeService.filesMeta.push(new FileMeta(name, documents.url, documents.size, null));
              }
            } else {
              this.allProfileForms.alterDocumentsControl(true);
            }
            // console.log(this.resumeService.filesMeta, this.allProfileForms.candidateForm.value)

            this.allProfileForms.candidateForm.setValue(resp);
            // console.log(this.allProfileForms.documents.value);
          } else if (role === 'admin') {
            this.allProfileForms.companyForm.setValue(resp);
          }
        } else {
          this.allProfileForms.companyForm.setValue(resp);
        }
      });
  }

}
