import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';


export interface IHomeFinderInfo {
  position: number;
  name: string;
  tmRank: number;
  candsHired: string;
  // candsInPipeline: string;
  tmScore: string;
  totalEarnings: number;
}

const ELEMENT_DATA: IHomeFinderInfo[] = [
  { position: 1, name: 'Finder 1', tmRank: 1.0079, candsHired: 'H', tmScore: 'Ne', totalEarnings: 29383737 },
  { position: 2, name: 'Finder 1', tmRank: 4.0026, candsHired: 'He', tmScore: 'Ne', totalEarnings: 283736 },
  { position: 3, name: 'Finder 1', tmRank: 6.941, candsHired: 'Li', tmScore: 'Ne', totalEarnings: 2923836 },
  { position: 4, name: 'Finder 1', tmRank: 9.0122, candsHired: 'Be', tmScore: 'Ne', totalEarnings: 23423 },
  { position: 5, name: 'Finder 1', tmRank: 10.811, candsHired: 'B', tmScore: 'Ne', totalEarnings: 100398 },
  { position: 6, name: 'Finder 1', tmRank: 12.0107, candsHired: 'C', tmScore: 'Ne', totalEarnings: 29999 },
  { position: 7, name: 'Finder 1', tmRank: 14.0067, candsHired: 'N', tmScore: 'Ne', totalEarnings: 98764 },
  { position: 8, name: 'Finder 1', tmRank: 15.9994, candsHired: 'O', tmScore: 'Ne', totalEarnings: 234 },
  { position: 9, name: 'Finder 1', tmRank: 18.9984, candsHired: 'F', tmScore: 'Ne', totalEarnings: 12345 },
  { position: 10, name: 'Finder 1', tmRank: 20.1797, candsHired: 'Ne', tmScore: 'Ne', totalEarnings: 1000 },
];



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  displayedColumns: string[] = ['position', 'name', 'tmRank', 'candsHired', 'tmScore', 'totalEarnings']
  dataSource = ELEMENT_DATA;
  finders: Observable<any>;

  

  constructor(
    private db: AngularFireDatabase, private _snackBar: MatSnackBar ) {
    this.finders = db.object('/users/').valueChanges();
  }
  ngOnInit() {
  }
 
    formatLabel(value: number) {
      if (value !>= 1000) {
        return Math.round(value) + 'miles';
      }
  
      return value + 'miles';
    }
    openSnackBar(message: string, action: string, radius: number) {
      
      this._snackBar.open(message, action);
    }

}
