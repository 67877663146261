import { Injectable } from '@angular/core';
import firebase from "firebase";

import { uploadcandidatesDocumentsPath } from '../../constants';
import { BioForm } from '../../forms/add-candidate/bio.form';
import { FileMeta } from '../../modal/file-meta';
import { AllProfileForms } from '../../forms/profiles/all-profiles.form';
import { Router } from '@angular/router';
import { CarrerProfilesForms } from '../../forms/profiles/carrer-profiles.form';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  uploadPath: string;
  filesMeta: Array<FileMeta> = [];
  // carrerProfileFileMeta: Array<{ name: string; url: string; size: number }> = [];
  toDeletFiles: string[] = [];

  constructor(
    private allProfileForms: AllProfileForms,
    private bioForm: BioForm,
    private router: Router,
    private carrerProfilesForms: CarrerProfilesForms,
  ) { this.uploadPath = uploadcandidatesDocumentsPath; }

  upload(role: any) {
    return new Promise((resolve) => {
      let toStopLoop = 0;

      if (this.filesMeta.length > 0) {
        for (const file of this.filesMeta) {
          if (file.files) {
            let fileName: any;
            const offsetRef = firebase.database().ref('.info/serverTimeOffset');
            offsetRef.on('value', (snap: any) => {
              const currentTime = Date.now() + snap.val();

              // The storage path
              fileName = currentTime + '_' + file.name;
            });

            // Reference to storage bucket
            const storageRef = firebase.storage().ref().child(`${this.uploadPath}/${fileName}`);

            // The main task
            storageRef
              .put(file.files)
              .then(async () => {
                // The file's download URL
                const downloadURL = await storageRef.getDownloadURL();
                toStopLoop++;

                if (role === 'recruiter') {
                  if (!this.bioForm.documents) {
                    this.bioForm.alterArrayControls(false, 'basicInfo', 'documents');
                  }
                  this.bioForm.adddocuments(fileName, downloadURL, file.size, true);
                } else if (role === 'candidate') {
                  if (this.router.url === '/profile/candidate') {
                    if (!this.allProfileForms.documents) {
                      this.allProfileForms.alterDocumentsControl(false);
                    }
                    this.allProfileForms.adddocuments(fileName, downloadURL, file.size);
                  } else if (this.router.url === '/candidate/my-tasks') {
                    if (!this.carrerProfilesForms.documents) {
                      this.carrerProfilesForms.alterDocumentsControl(false);
                    }
                    this.carrerProfilesForms.adddocuments(fileName, downloadURL, file.size);
                  }
                }

                if (toStopLoop === this.filesMeta.length) {
                  resolve('');
                }
              });
          } else {
            toStopLoop++;

            if (toStopLoop === this.filesMeta.length) {
              resolve('');
            }
          }
        }
      } else {
        resolve('');
      }

    });

  }

  deleteSingle(name: any) {
    return new Promise((resolve, reject) => {
      firebase.storage().ref().child(`${this.uploadPath}/${name}`).delete()
        .then(() => resolve(''));
    });
  }

  deleteMultiple() {
    return new Promise((resolve) => {
      if (this.toDeletFiles.length > 0) {
        let index = 1;
        for (const name of this.toDeletFiles) {
          firebase.storage().ref().child(`${this.uploadPath}/${name}`)
            .delete()
            .then(() => {
              if (index === this.toDeletFiles.length)
                resolve('');
              index++;
            });
        }
      } else {
        resolve('');
      }
    });
  }

  checkFormDocumentsLength(checkPage: any): number {
    if (checkPage === 'candidate-profile') {
      if (this.allProfileForms.documents) {
        return this.allProfileForms.documents.length;
      }

      return 0;
    } else if (checkPage === 'recruiter-pipeline') {
      if (this.bioForm.documents) {
        return this.bioForm.documents.length;
      }

      return 0;
    }
    return 0;
  }
}
