// ====================== Components ======================
import { AppComponent } from './app.component';

import { HeaderComponent } from './components/common/header/header.component';
import { HomeComponent } from './components/common/home/home.component';
import { FooterComponent } from './components/common/footer/footer.component';

// ====================== Services ======================
import { ResumeService } from './core/services/shared/resume.service';
import { AuthService } from './core/services/auth/auth.service';
import { AuthGuard } from './core/guards/auth.guard';

// ====================== Modules ======================
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ExportAsModule } from 'ngx-export-as';

// ====================== Randoms ======================
import { environment } from '../environments/environment';
import { BioForm } from './core/forms/add-candidate/bio.form';
import { AllProfileForms } from './core/forms/profiles/all-profiles.form';
import { SharedService } from './core/services/shared/shared.service';
import { MaterialModule } from './material.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from './shared/shared.module';
import { DynamicTable } from './components/randoms/submission/submited-candidate-view/dynamicTable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from './components/shared/loader/loader.module';
import { GoogleChartModule } from './google-chart/google-chart.module';


@NgModule({
  declarations: [
    AppComponent,

    HeaderComponent,
    HomeComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    GoogleChartModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    MaterialModule,
    SharedModule,
    ExportAsModule,
    LoaderModule
  ],
  providers: [
    AuthService,
    AuthGuard,

    BioForm,
    AllProfileForms,

    ResumeService,
    SharedService,
    DynamicTable,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
