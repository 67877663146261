export const ContactTypes = [
  'Email',
  'Phone',
  'In Person',
  'Video Conference',
  'LinkedIn',
  'Text',
  'Social Media'
];

export const RecType = [
  'Sleuth',
  'SME',
  'Military',
  'Corporate',
  'Executive',
  'Diversity',
  'Strategist',
  'Campus Champ',
  'Industry Expert',
  'Networking Guru',
  'Data Whiz',
  'Location Specialist'
];

export const CandidateAvailability = [
  'ASAP',
  '2 Weeks Notice',
  'Months',
  '30 Days',
  'For the Right Opportunity',
  'Not Available'
];

export const YesNoArray = [
  'Yes',
  'No'
];

export const jobPostingIndustries = [
  'Accounting',
  'Administration and Office Support',
  'Arts, and Media',
  'Financial Services',
  'Customer Service',
  'Construction',
  'Consulting and Strategy',
  'Education and Training',
  'Engineering',
  'Executive',
  'Farming, Animals, and Conservation',
  'Financial Services',
  'Healthcare and Medical',
  'Hospitality and Tourism',
  'Human Resources',
  'Recruitment',
  'Insurance',
  'Legal',
  'Manufacturing, Transport, and Logistics',
  'Marketing and Communications',
  'Real Estate',
  'Retail',
  'Sales',
  'Technology',
  'Sports and Recreation',
  'Trades and Services',
  'Supply Chain',
  'Essential Workers'
];

export const jobPostingEmploymentStatus = [
  'Full Time',
  'Part Time',
  'Seasonal',
  'Intern',
  'Consulting',
  'Hourly',

  // 'Full Time',
  // 'Part Time',
  // 'Contract / Consulting',
  // 'Seasonal',
  // 'Internship',
  // 'Ad-Hoc',
  // 'Campus Graduate',
];

export const jobPostingExperienceLevel = [
  'Intern',
  'Entry Level',
  'Junior',
  'Mid-level',
  'Senior',
  'Lead',
  'Executive'

  // ====== old field
  // 'Student',
  // 'Intern',
  // 'Junior',
  // 'Mid Level',
  // 'Senior',
  // 'Lead',
  // 'Executive',
  // 'Any'
];

export const jobPostingPostingType = [
  'Pipeline',
  'Private',
  'Public',
  'Retained',
  'Applications Only',
  'Mogum Posting'

  // ====== old field
  // 'Company',
  // 'Agency',
  // 'Recruiter Pipeline'
];

export const jobPostingHireType = [
  'FullTime',
  'PartTime',
  'Seasonal',
  'Intern',
  'Consulting',
  'Gig'
];

export const payFrequency = [
  'Hourly',
  'Monthly',
  'Daily',
  'Weekly',
  'Annually',
];

export const interviewType = [
  'Phone',
  'Video Conference',
  'In Person',
  'Final Round',
  'Panel Interview',
  'Extended'
];

export const declineReason = [
  'Skills not a fit',
  'Non-Responsive',
  'Not enough experience',
  'Compensation requirements not fit',
  'Candidate no longer interested',
];


export const genders = [
  'She	/	Her /	Hers',
  'He	/	Him	/	His',
  'They	/	Them	/	Theirs	/	Ze	/	Hir',
  'Prefer not to disclose',
];

export const GeoLocationSetting = {
  types: ['(regions)'],
  componentRestrictions: { country: 'us' }
};

export const uploadcandidatesDocumentsPath = '/candidatesDocuments';
