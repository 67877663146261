import { Component, OnInit } from '@angular/core';
import { GoogleChartService } from '../service/google-chart.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {

  private gLib: any;

  constructor(private gChartService : GoogleChartService) { 
    this.gLib = this.gChartService.getGoogle();
    this.gLib.charts.load('current', {'packages':['corechart','table']});
    this.gLib.charts.setOnLoadCallback(this.drawChart.bind(this));
  }

  ngOnInit() {

  }

  private drawChart(){
    let data = this.gLib.visualization.arrayToDataTable([
      ['Month', 'Postings', 'Hires'],
      ['January',  1000,      150],
      ['February',  1170,      250],
      ['March',  660,       330],
      ['April',  1030,      4100],
      ['May',  1030,      102],
      ['June',  1030,     87],
      ['July',  1030,      89],
      ['August',  1030,      76],
      ['September',  1030,      210],
      ['October',  1030,      150],
      ['November',  1030,      175],
      ['December',  1030,      89]


    ]);
    let options = {'title':'Hires over time',
                    'width': '100%',
                    'height': '100%',
                  'fill':'transparent',};

    let chart = new this.gLib.visualization.LineChart(document.getElementById('divLineChart'));

    chart.draw(data, options);
  }

}
