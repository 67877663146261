import { RouterModule, Routes } from '@angular/router'

import { HomeComponent } from './components/common/home/home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RoutePaths } from './core/routes';

const routes: Routes = [

  // =================== Unmatched/Home Routes ===================
  { path: RoutePaths.blank, redirectTo: '/', pathMatch: 'full' },
  { path: RoutePaths.blank, component: HomeComponent },
  // =================== Auth ===================

  {
    path: RoutePaths.auth.signin,
    loadChildren: () => import('./components/auth/signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: RoutePaths.auth.signup,
    loadChildren: () => import('./components/auth/signup/signup.module').then(m => m.SignupModule)
  },

  // =================== Only Auth Users Can Access ===================

  // Candidates
  {
    path: RoutePaths.candidate.parent,
    loadChildren: () => import('./layouts/candidate-layout/candidate-layout.module').then(m => m.CandidateLayoutModule),
    canActivate: [AuthGuard]
  },

  // Recruiters
  {
    path: RoutePaths.recruiter.parent,
    loadChildren: () => import('./layouts/recruiter-layout/recruiter-layout.module').then(m => m.RecruiterLayoutModule),
    canActivate: [AuthGuard]
  },

  // Company
  {
    path: RoutePaths.company.parent,
    loadChildren: () => import('./layouts/company-layout/company-layout.module').then(m => m.CompanyLayoutModule),
    canActivate: [AuthGuard]
  },

  // Profile
  {
    path: RoutePaths.profile,
    loadChildren: () => import('./components/randoms/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },

  // Admin
  {
    path: RoutePaths.admin.parent,
    loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
    canActivate: [AuthGuard]
  },
]

export const AppRoutingModule = RouterModule.forRoot(routes);
