import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AllProfileForms {

  basicInfo: FormGroup;
  recruiterForm: FormGroup;
  candidateForm: FormGroup;
  companyForm: FormGroup;
  public arrayEducation = [];
  public arrayExperience = [];
  Education = false;
  Experience = false;


  constructor(private fb: FormBuilder) { }

  initBasicInfoForm() {
    return this.basicInfo = this.fb.group({
      fullName: this.fb.control('', [Validators.required]),
      email: this.fb.control({
        value: '',
        disabled: true
      },
        Validators.required
      ),
      phone: this.fb.control('', [Validators.required]),
      address: this.fb.control(''),
      dob: this.fb.control(''),
      gender: this.fb.control(''),
      almaMater: this.fb.control(''),
      image: this.fb.control(''),
      role: this.fb.control(''),
      linkedIn: this.fb.control(''),
      website: this.fb.control('')
      // display name eventually
    });
  }

  initRecruiterForm() {
    return this.recruiterForm = this.fb.group({
      industry: this.fb.control('', [Validators.required]),
      availability: this.fb.control('', [Validators.required]),
      skillsFocus: this.fb.control(''),

      // updated recruiterForm (modeling new Data Schema)
      recType: this.fb.control(''),
      recBio: this.fb.control(''),
      // engagedPostings: new FormControl('', [
      // ]),
      tmLevel: this.fb.control('Beginner'),
      tmScore: this.fb.control('0'),
      tmRank: this.fb.control('N/A'),
      candsHired: this.fb.control('0'),
      currentSubmissions: this.fb.control('0'),
      totalSubmissions: this.fb.control('0'),
      tmDesignation: this.fb.control('Rookie'),

      //    NEW STUFF FEB 23

      pipeline: this.fb.control(''),
      submissionAverage: this.fb.control('0'),
      finderSubscriptionType: this.fb.control('Free'),
      earnings: this.fb.control('0.00'),
      connections: this.fb.control(''),
      engagements: this.fb.control(''),
      submissionRate: this.fb.control(''),
      positiveSubmissions: this.fb.control('0'),
      negativeSubmissions: this.fb.control('0'),
    });
  }
  initCandidateForm() {
    return this.candidateForm = this.fb.group({
      summary: this.fb.control(''),
      professionalExperience: this.fb.control(''),
      educationExperience: this.fb.control(''),
      canRelocate: this.fb.control(''),
      possibleLocations: this.fb.control(''),
      canStart: this.fb.control(''),
      isEmployed: this.fb.control(''),
      seeking: this.fb.control(''),
      submittedTo: this.fb.control(''),
      candidateType: this.fb.control(''),
      isOpen: this.fb.control(''),
      profileValidated: this.fb.control(''),
      publicProfile: this.fb.control(''),
      isPaidUser: this.fb.control(''),
      // working on mock-up for full Candidate Profile that can be mirrored. WIP WIP WIP WIP
      // step 1


      // generalInfo: this.fb.group({
      //     email2: this.fb.control(''),
      //     phone2: this.fb.control(''),
      //     tags: this.fb.array([]),
      //     industry: this.fb.array([]),
      //     website: this.fb.control(''),
      //     facebook: this.fb.control(''),
      //     instagram: this.fb.control(''),
      //     canRelocate: this.fb.control(''),
      //     possibleLocations: this.fb.array([]),
      //     canStart: this.fb.control(''),
      //     seeking: this.fb.array([])
      // }),

      // step 2
      // education: this.fb.array([this.createEducation()]),

      // step 3
      // experience: this.fb.array([this.createExperience()]),

      // step 4
      // salaryInfo: this.fb.group({
      //     currentSalary: this.fb.control(''),
      //     currentTC: this.fb.control(''),
      //     currentVC:this.fb.control(''),
      //     desiredRangeLow: this.fb.control(''),
      //     desiredRangeHigh: this.fb.control(''),
      //     compNotes: this.fb.array([]),
      //     payFrequency: this.fb.control(''),
      // }),
      documents: this.fb.array([])
    });
  }
  // end candidate forms
  // start company stuff

  initCompanyForm() {
    return this.companyForm = this.fb.group({
      companyName: this.fb.control('', [Validators.required]),
      companyHQ: this.fb.control('', [Validators.required]),
      companyDescription: this.fb.control('', [Validators.required]),
      customerType: this.fb.control(''),
      companyRating: this.fb.control(''),
      vendorAgreement: this.fb.control(''),
      companyWebsite: this.fb.control(''),
      companyLogo: this.fb.control(''),
      companyIndustry: this.fb.control(''),
    });
  }

  // For Candidate's Documents
  createDocument(name: string, url: string, size: number) {
    return this.fb.group({
      name: name,
      url: url,
      size: size
    });
  }


  // Creating Arrays
  createEducation() {
    return this.fb.group({
      schoolName: '',
      graduationDateFrom: '',
      graduationDateTo: '',
      expectedGraduationDate: '',
      currentlyEnrolled: false,

      degree: '',
      major: '',
      minor: '',
      GPA: '',
    });
  }

  createExperience() {
    return this.fb.group({
      companyName: '',
      jobTitle: '',
      startDate: '',
      endDate: '',
      address: '',
      responsibilities: this.fb.array([]),
    });
  }

  get documents(): FormArray {
    return this.candidateForm.get('documents') as FormArray;
  }

  removeDocuments(index: any) {
    this.documents.removeAt(index);
  }

  adddocuments(name: string, url: string, size: number) {
    this.documents.push(this.createDocument(name, url, size));
  }

  alterDocumentsControl(altertext: boolean) {
    if (altertext) {
      this.candidateForm.removeControl('documents');
    } else {
      this.candidateForm.addControl('documents', this.fb.array([]));
    }
  }
  // Gettign Education / Experience
  get education() {
    return this.candidateForm.get('education') as FormArray;
  }

  get experience() {
    return <FormArray>this.candidateForm.get('experience');
  }
  // Adding Arrays
  addEducation() {
    this.education.push(this.createEducation());
  }

  addExperience() {
    this.experience.push(this.createExperience());
  }

  updateFinderScore() {
    return this.recruiterForm.get('tmScore');
    // Add Code to implement additional updates of Scores and designations...
  }

}
