import { Component, OnInit } from '@angular/core';
import { GoogleChartService } from '../service/google-chart.service';

@Component({
  selector: 'app-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: ['./table-chart.component.css']
})
export class TableChartComponent implements OnInit {

  private gLib: any;

  constructor(private gChartService : GoogleChartService) { 
    this.gLib = this.gChartService.getGoogle();
    this.gLib.charts.load('current', {'packages':['corechart','table']});
    this.gLib.charts.setOnLoadCallback(this.drawChart.bind(this));
  }
  ngOnInit() {

  }

  private drawChart(){
    let chart = new this.gLib.visualization.Table(document.getElementById('divTableChart'));
    let data = new this.gLib.visualization.DataTable();

    data.addColumn('number', 'Rank');
    data.addColumn('string', 'Finder Name');
    data.addColumn('number', 'Finder Score');
    data.addColumn('number', 'Finder Rating');    
    data.addColumn('string', 'Finder Earnings');
    data.addColumn('number', 'Finder Hires');


    data.addRows([
      [1, 'test finder', 2343, 87, 'Gold',27346],
      [2, 'test finder', 2343, 87, 'Gold',27346],
      [3, 'test finder', 2343, 87, 'Gold',27346],
      [4, 'test finder', 2343, 87, 'Gold',27346],
      [5, 'test finder', 2343, 87, 'Gold',27346],
      [6, 'test finder', 2343, 87, 'Gold',27346],
      [7, 'test finder', 2343, 87, 'Gold',27346],
      [8, 'test finder', 2343, 87, 'Gold',27346],
      [9, 'test finder', 2343, 87, 'Gold',27346],
      [10,'test finder', 343, 87,'Gold',27346],
      
    ]);

    let options = {'title':'Sales Tabular Data',
                       'width': '100%',
                       'height': '100%',
                      'background': 'transparent',
                      'headerRow': 'italic-darkblue-font large-font bold-font',
                      'showRowNumber': true,
                      'rowHeight': '10vh',
                    }
                    ;
                    let formatter = new this.gLib.visualization.ColorFormat();
                    {formatter.addRange(1,3, 'gold', 'black');}

    chart.draw(data, options, formatter);
  }
  

}
