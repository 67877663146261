import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loader = new BehaviorSubject(false);
  constructor() { }

  displayLoader() {
    this.loader.next(true);

    setInterval(() => {
      this.loader.next(false);
    }, 3000);
  }
}
