import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private http: HttpClient
  ) { }

  getSchoolNames() {
    return this.http.get('https://api.data.gov/ed/collegescorecard/v1/schools?&api_key=33fuZyv2qGDE8wO0fVsuE6j0jkaKWC3MBxggP19e');
  }
}
