import { environment } from 'src/environments/environment';

export const APIs = {

  // auth
  searchByEmail: environment.baseURL + 'auth/searchByEmail',
  signin: environment.baseURL + 'auth/signin',

  // Recruiter
  sendConnection: environment.baseURL + 'recruiter/sendConnection',
  submitPostToUserCandidate: environment.baseURL + 'recruiter/submitPostToUserCandidate',
  updateCandidatePhaseStatus: environment.baseURL + 'recruiter/updateCandidatePhaseStatus',
  updatefinderScore: environment.baseURL + 'recruiter/updatefinderScore',
  updateCandidateSkills: environment.baseURL + 'recruiter/updateCandidateSkills',
  recSoftDeleteSubmission: environment.baseURL + 'recruiter/recSoftDeleteSubmission',
  disengagePosting: environment.baseURL + 'recruiter/disengagePosting',
  removeFormDisengagePostingList: environment.baseURL + 'recruiter/removeFormDisengagePostingList',
  getDisengagePosting: environment.baseURL + 'recruiter/getDisengagePosting/',

  // Candidate
  getConnection: environment.baseURL + 'candidate/getConnection',
  setConnectionStatus: environment.baseURL + 'candidate/setConnectionStatus',
  getUserCandidateSubmissions: environment.baseURL + 'candidate/getUserCandidateSubmissions',
  reactOnPosting: environment.baseURL + 'candidate/reactOnPosting',
  createCustomJobPosting: environment.baseURL + 'candidate/createCustomJobPosting/',
  getCustomJobPosting: environment.baseURL + 'candidate/getCustomJobPosting/',
  deleteCustomJobPosting: environment.baseURL + 'candidate/deleteCustomJobPosting/',
  updateCustomJobPosting: environment.baseURL + 'candidate/updateCustomJobPosting/',
  submitUnassignedResponsibilities: environment.baseURL + 'candidate/submitUnassignedResponsibilities/',
  getUnassignedResponsibilities: environment.baseURL + 'candidate/getUnassignedResponsibilities/',
  updateUnassignedResponsibilities: environment.baseURL + 'candidate/updateUnassignedResponsibilities/',
  removeUnassignedResponsibilities: environment.baseURL + 'candidate/removeUnassignedResponsibilities/',
  getCareerProfile: environment.baseURL + 'candidate/getCareerProfile/',
  candSoftDeleteSubmission: environment.baseURL + 'candidate/candSoftDeleteSubmission/',

  // Company
  companyPermanentlyDeletePosting: environment.baseURL + 'company/companyPermanentlyDeletePosting/',
  companySoftDeletePosting: environment.baseURL + 'company/companySoftDeletePosting/',

};
