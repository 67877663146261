export class FileMeta {
  name: string;
  url: string;
  size: number;
  files: File;

  constructor(name: string, url: string, size: number, files: File) {
    this.name = name;
    this.url = url;
    this.size = size;
    this.files = files;
  }
}
