import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile/profile.service';
import { RoutePaths } from 'src/app/core/routes';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  // admin user
  adminStatus = false;
  authStatus = false;
  // normal users
  UserProfile: any;
  routePaths: any;
  showDelay = new FormControl(1000);
  hideDelay = new FormControl(1000);
  showNotification: any;
  constructor(
    private db: AngularFireDatabase,
    private authServ: AuthService,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.routePaths = RoutePaths;
    // this.authServ.user$.subscribe((u:firebase.User) => {
    // 	if(u){
    // 		this.UserProfile = u;
    // 	}
    // })
  }

  ngAfterViewInit() {
    this.getProfile();
    this.authServ.authStatus()
      .subscribe(resp => this.authStatus = resp);
  }
  // ----------- User Auth --------------
  getProfile() {
    // actual fetching method
    // this.authServ.getUserBasic()
    //   .subscribe(snapshots => {
    //     this.UserProfile = snapshots;
    //   });

    // Temp fetching method
    this.authServ.user$.subscribe(res => {
      if (res && res.uid) {
        this.db.object('/users/' + res.uid)
          .valueChanges()
          .subscribe((snapshots) => {
            this.UserProfile = snapshots;
            this.profileService.profileDict = snapshots;
          });
      }
    });
  }

  signout() {
    this.authServ.signout();
    this.UserProfile = undefined;
  }
  openNotification(state: boolean) {
    this.showNotification = state;
  }
}
