import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor() { }

  messages = {

    invalidFormTitle: 'Invalid form!',
    invalidFormMsg: 'Please add valid form details',

    updatedTitle: 'Updated!',
    updateMsg: 'Details updated successfully',

    AddTitle: 'Added!',
    AddMsg: 'Details added successfully',

    failedTitle: 'Sorry!',
    failedText: 'Something went wrong',

    removedTitle: 'Permanently removed!',

    profileUpdateTitle: 'Profile details changed',
    profileUpdateText: 'Your profile details have been successfully updated!',

    signUp: {
      emailVerificationTitle: 'Mogum',
      emailVerificationText: 'Please check your inbox for account activation instructions.'
    },

    signIn: {
      successTitle: 'Welcome to Mogum!',
      successText: 'You are logged in now',
    },

    forgotPassword: {
      successTitle: 'Mogum',
      succeessText: 'Password reset instructions have been sent to your email. '
    },

    signOut: {
      Title: 'Mogum',
      Text: 'Thanks for visiting us'
    },

    docFileValidation: {
      invalidFileTypeTitle: 'Invalid Format!',
      invalidFileTypeText: 'Please select .pdf .doc .docx file format',

      fileSizeLimitTitle: 'Huge File! <br/> File size must be less then or equal to 500 KB',

      filesCountLimitTitle: 'Limit Exceded!',
      filesCountLimitText: 'Maximum three files are allowed',

      fileExistTitle: 'Already Exist!',
      fileExistText: 'This file already exist in your list.',
    },

    emailVerifyText: 'Please verify your email',
    emailVerifyTitle: 'Sorry!',

    questionsText: 'After you delete this, it cant be recovered.',

    companyJobPosting: {
      invalidProfileFieldsText: 'Please complete your profile first.',

      jobDraftTitle: 'Job posted!',
      jobDraftText: 'Please submit this job from posting view section. so recruiters can engage',

      jobSubmittedTitle: 'Congratulations, your posting is live!',
      jobSubmittedText: 'This posting is now live and available for engagement by members of the Mogum Finder Network.',

      jobAlreadySubmittedTitle: 'Job already submitted!',
      jobAlreadySubmittedText: 'This posting is already live. Would you like to remove the posting?',
      jobEngagedTitle: 'Engaged!',
      jobEngagedText: 'Now you are engaged with this jobs',
      jobAlreadyEngagedTitle: 'You are already engaged!',
    },

    submissions: {
      candidateSubmittedTitle: 'Submitted',
      candidateSubmittedText: 'Candidated submitted to current position',

      candidateDraftTitle: 'Considering',
      candidateDraft(candNum:any) {
        return `Current position marked as considering for ${candNum} candidate`
      },
      candidateDraftText: 'Current position marked as considering for this candidate',

      candidateHMReviewTitle: 'Hiring Manager Review',
      candidateHMReviewText: 'Candidate currently under review by Hiring Manager',

      candidateInterviewingTitle: 'Candidate Interviewing',
      candidateInterviewingText: 'Candidate currently in the interview process',

      candidateAdditionalInterviewsTitle: 'Requesting Additional Interviews',
      candidateAdditionalInterviewsText: 'Hiring Manager has requested additional Interviews',

      candidateInterviewsCompleteTitle: 'Interviews Complete',
      candidateInterviewsCompleteText: 'Interviews complete - Now Calibrating feedback',

      candidateExtendOfferTitle: 'Extend Offer',
      candidateExtendOfferText: 'Hiring Manager would like to extend offer to candidate',

      candidateOfferAcceptedTitle: 'Candidate has Accepted Offer',
      candidateOfferAcceptedText: 'Candidate accepted offer - CONGRATULATIONS! Update submission with agreed upon start date',

      candidateStartDateScheduledTitle: 'Start date Confirmed',
      candidateStartDateScheduledText: 'Start Date Confirmed - Pending BG Checks and start acknowledgement',
      candidateHiredTitle: 'Candidate Hired',
      candidateHiredText: 'Candidate Hired for position - awaiting start acknowledgment',
     
      candidatesResponsibiltyTitle: 'Skill added!',
      candidatesResponsibiltyText(candNum:any) {
        return `Current skill added for ${candNum} candidate`
      }
    },

    connection: {
      title: 'Mogum',
      sent: 'Conection Request Sent',
      approvied: 'Conection Request Approvied',
      rejected: 'Conection Request Rejected',
      removed: 'Candidate removed from list',
    }

  };

  successWithoutConfirmButton(title: string, text: string) {
    Swal.fire({
      icon: 'success',
      title: title,
      text: text,
      timer: 2500,
      showCancelButton: false,
      showConfirmButton: false
    });
  }

  successWithConfirmButton(title: string, text: string) {
    Swal.fire({
      icon: 'success',
      title: title,
      text: text,
      showCancelButton: true,
      showConfirmButton: true
    });
  }

  errorAlert(title: string, text: string) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: text,
      showCancelButton: false,
      showConfirmButton: true
    });
  }

  warningAlert(title: string, text: string) {
    Swal.fire({
      icon: 'warning',
      title: title,
      text: text,
      showCancelButton: false,
      showConfirmButton: true
    });
  }


  quesAlert(text: string) {
    return Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, keep it'
    });
  }

  forgotPasswordAlert() {
    return Swal.fire({
      title: 'Enter you email address to receive a link allowing you to reset your password.',
      input: 'email',
      inputPlaceholder: 'Enter your email address',
      confirmButtonText: 'SUBMIT'
    })
  }
  toggleListButton() {
    Swal.fire({
      icon: 'warning',
      title: 'Toggle to list view to download',
      showCancelButton: false,
      showConfirmButton: true
    });
  }

  emailsAlreadyExists(arr:any) {
    let msg = ''
    arr.forEach((item:any, index:any) => {
      msg = index == 0 ? item : msg + ',' + item
    });
    Swal.fire({
      icon: 'info',
      title: 'From list these candidates already exists',
      text: msg,
      showCancelButton: false,
      showConfirmButton: true
    });
  }

  async testStepsMessage() {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Reason for submission',
      inputAttributes: {
        'aria-label': 'Submission reason from Finder'
      },
      showCancelButton: true,
      showConfirmButton: true
    })

    if (text) {
      Swal.fire(text)
    }
  }

}
