import { FormGroup, FormBuilder, Validators, FormArray, FormControl, Form } from '@angular/forms';
import { Optional, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarrerProfilesForms {

  carrerProfile: FormGroup;
  UnassignedResponsibilities: FormGroup;
  filterForm: FormGroup;

  constructor(private fb: FormBuilder) { }

  initForm() {
    return this.carrerProfile = this.fb.group({
      basicInfo: this.fb.group({
        documents: this.fb.array([]),
        address: null,
        email: {
          value: null,
          disabled: true
        },
        phone: null,
        summary: null,
      }),
      education: this.fb.array([this.createEducation()]),
      experience: this.fb.array([this.createExperience()]),
      skillsAndIndustries: this.fb.group({
        functionalSkills: this.fb.array([]),
        industries: this.fb.array([]),
        licensesCertifications: this.fb.array([]),
      }),
      careerPreferences: this.fb.group({
        location: null,
        contactEmail: null,
        phone: null,
        abletoRelocate: null,
        marketsOfInterest: null,
        salaryExpectationsLow: null,
        salaryExpectationsHigh: null,
        motivators: null,
        availability: null,
      }),
      selfIdentification: this.fb.group({
        work_authorization: null,
        needs_sponsorship: null,
        hispanic_latino: null,
        race: null,
        gender: null,
        disability: null,
        veteran: null,
        vetStatus: null
      }),
    });
  }

  createEducation() {
    return this.fb.group({
      schoolName: null,
      graduationDateFrom: null,
      graduationDateTo: null,
      degree: null,
      major: null,
      minor: null,
    });
  }

  createExperience() {
    return this.fb.group({
      companyName: null,
      jobTitle: null,
      startDate: null,
      endDate: null,
      responsibilities: this.fb.array([]),
    });
  }

  createDocument(name: string, url: string, size: number) {
    return this.fb.group({
      name: name,
      url: url,
      size: size
    });
  }


  get documents(): FormArray {
    return this.carrerProfile.get('basicInfo.documents') as FormArray;
  }

  get education() {
    return this.carrerProfile.get('education') as FormArray;
  }

  get experience() {
    return <FormArray>this.carrerProfile.get('experience');
  }

  // Adding Arrays
  addEducation() {
    this.education.push(this.createEducation());
  }

  addExperience() {
    this.experience.push(this.createExperience());
  }

  adddocuments(name: string, url: string, size: number) {
    this.documents.push(this.createDocument(name, url, size));
  }

  alterDocumentsControl(altertext: boolean) {
    if (altertext) {
      this.carrerProfile.removeControl('documents');
    } else {
      this.carrerProfile.addControl('documents', this.fb.array([]));
    }
  }

  removeDocuments(index:any) {
    this.documents.removeAt(index);
  }


  // Removeing Arrays
  removeEducation(index:any) {
    this.education.removeAt(index);
    if (this.education.length === 0) {
      this.addEducation();
    }
  }

  removeExperience(index:any) {
    this.experience.removeAt(index);
    if (this.experience.length === 0) {
      this.addExperience();
    }
  }

  // ================ Experience Responsibility

  getExpResponsibility(index: number) {
    return this.experience.controls[index].get('responsibilities') as FormArray;
  }

  addExpResponsibility(index: number) {
    this.getExpResponsibility(index).push(this.createUnassignedResponsibilities());
  }

  removeExpResponsibility(expIndex: number, respIndex: number) {
    const control = this.experience.controls[expIndex].get('responsibilities') as FormArray;
    control.removeAt(respIndex);
  }

  // ====================== Chips

  get skills() {
    return <FormArray>this.carrerProfile.get('skillsAndIndustries.functionalSkills');
  }

  addSkills(item: any) {
    if (typeof (item) === 'string') {
      this.skills.push(this.createChips(item));
      return;
    }
    item.map((i:any) => this.skills.push(this.createChips(i.name)));
  }

  removeSkills(childIndex: number) {
    this.skills.removeAt(childIndex);
  }


  get industries() {
    return <FormArray>this.carrerProfile.get('skillsAndIndustries.industries');
  }

  addIndustries(item: any) {
    if (typeof (item) === 'string') {
      this.industries.push(this.createChips(item));
      return;
    }
    item.map((i:any) => this.industries.push(this.createChips(i.name)));
  }

  removeIndustries(childIndex: number) {
    this.industries.removeAt(childIndex);
  }


  get licensesCertifications() {
    return <FormArray>this.carrerProfile.get('skillsAndIndustries.licensesCertifications');
  }

  addLicensesCertifications(item: any) {
    if (item) {
      if (typeof (item) === 'string') {
        this.licensesCertifications.push(this.createChips(item));
        return;
      }
      item.map((i:any) => this.licensesCertifications.push(this.createChips(i.name)));
    }
  }

  removeLicensesCertifications(childIndex: number) {
    this.licensesCertifications.removeAt(childIndex);
  }

  // ====== shared Methods

  createChips(name: string) {
    return this.fb.group({
      name: name
    });
  }

  // ============================= Unassigned Responsibilities Form

  responsibilitiesInitForm() {
    return this.UnassignedResponsibilities = this.fb.group({
      experienceID: '',
      description: '',
      include: false
    });
  }

  createUnassignedResponsibilities() {
    return this.fb.group({
      experienceID: '',
      description: '',
      include: false
    });
  }

  get unassignedResponsibilities() {
    return this.UnassignedResponsibilities.get('responsibilities') as FormArray;
  }

  addunassignedResponsibilities() {
    this.unassignedResponsibilities.push(this.createUnassignedResponsibilities());
  }

  removeunassignedResponsibilities(index: number) {
    this.unassignedResponsibilities.removeAt(index);
    if (this.unassignedResponsibilities.length === 0) {
      this.addunassignedResponsibilities();
    }
  }

  // ============================= Filters form

  initFilterForm() {
    return this.filterForm = this.fb.group({
      status: ['', Validators.required],
      posting: ['', Validators.required],
      company: ['', Validators.required]
    });
  }

}
