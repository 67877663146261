import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstName'
})
export class FirstNamePipe  {

  transform(value: any, args?: any): any {
    return value && value.toLowerCase().split(' ')[0];
  }

}
