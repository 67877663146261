import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AlertsService } from '../sweetalert/alerts.service';

import { Observable } from 'rxjs/Observable';
import { isNullOrUndefined } from 'util';
import { HttpClient } from '@angular/common/http';
import { APIs } from '../../constants/apis';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<firebase.User>;
  userID:any;

  token:any;
  userRole = '';

  constructor(
    private db: AngularFireDatabase,
    private router: Router,
    private afAuth: AngularFireAuth,
    private alertsService: AlertsService,
    private http: HttpClient) {

    this.user$ = this.afAuth.authState;

    this.getUserID();

  }

  // ============ Signup
  signup(data: {
    email: string,
    password: string,
    fullName: string,
    image: string,
    role: string
  }) {
    this.afAuth.createUserWithEmailAndPassword(data.email, data.password)
      .then(() => {
        let user: any = firebase.auth().currentUser;

        user.sendEmailVerification()
          .then(() => {
            this.db.object('users/' + user.uid)
              .set({
                fullName: data.fullName,
                email: data.email,
                image: '',
                role: data.role
              })
              .then(resp => {
                this.afAuth.signOut();

                this.alertsService.successWithConfirmButton(
                  this.alertsService.messages.signUp.emailVerificationTitle,
                  this.alertsService.messages.signUp.emailVerificationText
                );
                this.router.navigate(['/signin']);
              })
              .catch(err => {
                this.alertsService.errorAlert('', `${err} `);
              })
          })
          .catch((err:any) => {
            this.alertsService.errorAlert('', `${err} `);
          });
      })
      .catch((err:any) => {
        this.alertsService.warningAlert('', `${err} `);
      })
  }
  // ============ Signin

  //  Google login

  loginGoogle() {
    this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(() => {
        this.afAuth.authState.subscribe(res => {
          if (res && res.uid) {

            this.db.object('/users/' + res.uid)
              .valueChanges()
              .subscribe(
                user => {
                  if (!user) {
                    this.db.object('/users/' + res.uid)
                      .update({
                        email: res.email,
                        fullName: res.displayName,
                        image: res.photoURL,
                        role: ''
                      }).catch(err => { this.errorMsgAlert(err); });
                  }
                });
          }
        });
        this.successLoginAlert();
        this.router.navigate(['/']);
      }).catch((error:any) => { this.errorMsgAlert(error); });
  }

  //  Facebook login
  loginFacebook() {
    this.afAuth.signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then(() => {
        this.afAuth.authState
          .subscribe(res => {
            if (res && res.uid) {
              this.db.object('/users/' + res.uid)
                .valueChanges()
                .subscribe(
                  user => {
                    if (!user) {
                      this.db.object('/users/' + res.uid)
                        .update({
                          email: res.email,
                          fullName: res.displayName,
                          image: res.photoURL,
                          role: ''
                        })
                        .catch(error => { this.errorMsgAlert(error); });
                    }
                  });
            }
          });
        this.successLoginAlert();
        this.router.navigate(['/']);
      }).catch((error:any) => {
        this.errorMsgAlert(error.message);
      });
  }

  //  Email Password login
  login(email: string, password: string) {
    this.afAuth.signInWithEmailAndPassword(email, password)
      .then(() => {
        const user: firebase.User | null = firebase.auth().currentUser;
        if (user.emailVerified) {
          this.successLoginAlert();
          this.router.navigate(['/']);
        } else {
          this.afAuth.signOut();
          this.alertsService.warningAlert(this.alertsService.messages.emailVerifyTitle, this.alertsService.messages.emailVerifyText);
        }
      }).catch((err:any) => {
        this.alertsService.warningAlert('Sorry!', `${err}`);
      });
  }

  // ============ Forgot Passowrd  Email
  forgotPasswordEmail() {
    this.alertsService.forgotPasswordAlert()
      .then((email) => {
        firebase.auth().sendPasswordResetEmail(email.value)
          .then(() => {
              this.alertsService.successWithoutConfirmButton(
                this.alertsService.messages.forgotPassword.successTitle,
                this.alertsService.messages.forgotPassword.succeessText,
              );
            }).catch((err:any) => {
              this.alertsService.errorAlert('', `${err}`);
            });
      });
  }

  // ============ Signout
  signout() {
    this.afAuth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
    this.alertsService.successWithoutConfirmButton(this.alertsService.messages.signOut.Title, this.alertsService.messages.signOut.Text);
  }

  // ============ Currently logged in user
  getUserID() {
    // let userid = firebase.auth().currentUser.uid;
    this.afAuth.authState.subscribe((res: firebase.User) => {
      if (res && res.uid)
        return this.userID = res.uid;

      return this.userID = undefined;
    });
  }

  // Returns current user
  currentUser(): firebase.User {
    // return this.authenticated ? this.authState.auth : null;
    return firebase.auth().currentUser;

  }

  // ============ Auth Check
  authStatus(): Observable<boolean> {
    return this.afAuth.authState.map(auth => {
      if (isNullOrUndefined(auth))
        return false;

      return true;
    });
  }
  // ============ End

  getUserBasic() {
    return this.db.object('/users/' + this.currentUser().uid).valueChanges();
  }

  getUserBasicWithDynamicUserID(userID:any) {
    return this.db.object('/users/' + userID).valueChanges();
  }

  getUserAdvance(role:any) {
    return this.db.object('/roles/' + role + '/' + this.userID).valueChanges();
  }

  getUserAdvanceByID(ID:any) {
    return this.db.object('/roles/Candidate/' + ID);
  }

  // ============ Sweet Alert
  successLoginAlert() {
    this.alertsService.successWithoutConfirmButton(this.alertsService.messages.signIn.successTitle,
      this.alertsService.messages.signIn.successText);
  }

  errorMsgAlert(msg:any) {
    this.alertsService.errorAlert(this.alertsService.messages.failedTitle, msg);
  }

  updateFinderScore() {
    console.log('Finder Score is... {{');
  }

  getUserByEmail(candEmail: string) {
    return this.http.post(APIs.searchByEmail, { email: candEmail });
  }
}
