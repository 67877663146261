import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Injectable, Optional } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class BioForm {

  BasicInfo = true;
  Education = false;
  Experience = false;
  Accommodation = false;

  public arrayEducation = [];
  public arrayExperience = [];
  public arrayTouchPoints = [];

  constructor(@Optional() private fb: FormBuilder) { }

  addCandidateForm = new FormGroup({});

  // initiate form
  initForm() {
    return this.addCandidateForm = this.fb.group({
      // step 1
      basicInfo: this.fb.group({
        candidateName: ['', Validators.required],
        email: '',
        email2: '',
        phone: '',
        phone2: '',
        address: '',
        linkedIn: '',
        source: '',
        status: '',
        quickSummary: '',
        isValidated: false,
        tags: this.fb.array([]),
        industry: this.fb.array([]),
        documents: this.fb.array([]),
        website: '',
        facebook: '',
        instagram: '',

      }),

      // step 2
      education: this.fb.array([this.createEducation()]),

      // step 3
      experience: this.fb.array([this.createExperience()]),

      // step 4
      salaryInfo: this.fb.group({
        currentSalary: '0',
        currentTC: '0',
        currentVC: '0',
        desiredRangeLow: '0',
        desiredRangeHigh: '',
        compNotes: this.fb.array([]),
        payFrequency: 'Annually',
      }),

      // step 5
      logisticsInfo: this.fb.group({
        canRelocate: false,
        possibleLocations: this.fb.array([]),
        canStart: '',
        isEmployed: false,
        seeking: this.fb.array([]),
        skills: this.fb.array([]),
      }),

      // step 6
      touchPoints: this.fb.array([this.createTouchPoints()])
    })
  }

  // Creating Arrays
  createEducation() {
    return this.fb.group({
      schoolName: '',
      graduationDateFrom: '',
      graduationDateTo: '',
      expectedGraduationDate: '',
      currentlyEnrolled: false,

      degree: '',
      major: '',
      minor: '',
      GPA: '',
    });
  }

  createExperience() {
    return this.fb.group({
      companyName: '',
      jobTitle: '',
      startDate: '',
      endDate: '',
      currentlyEmployed: '',
      address: '',
      responsibilities: this.fb.array([]),
    });
  }

  prefillDynamicFields(data: any, key: string) {
    if (data && data.length) {
      const control = <FormArray>this.addCandidateForm.controls[key];
      for (let i = 0; i < data.length - 1; i++) {
        switch (key) {
          case 'education':
            control.push(this.createEducation());
            break;
          case 'experience':
            control.push(this.createExperience());
            break;
          default:
            break;
        }
      }
      const length = control.length;
      for (let i = 0; i < length; i++) {
        control.at(i).patchValue(data[i]);
        if (key === 'experience') {
          this.addResponsibilities(data[i].responsibilities, i);
        }
      }
    }
  }

  createTouchPoints() {
    let data = new Date()
    return this.fb.group({
      contactType: 'Created',
      contactPurpose: 'Candidate first added to Genpipe',
      contactOutcome: '',
      recruiterSummary: '',
      // timeStamp: 'currentDay / currentMonth / currentYear',
      timeStamp: `${data.getDate()} / ${this.getMonth(data.getMonth())} / ${data.getFullYear()}`
    });
  }

  getMonth(m: any) {
    return Reflect.get({
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    }, m)
  }

  createDocument(name: string, url: string, size: number, isResume: boolean,) {
    return this.fb.group({
      name: name,
      url: url,
      size: size,
      isResume: false,
    });
  }

  // Getting Arrays
  get education() {
    return this.addCandidateForm.get('education') as FormArray;
  }

  get experience() {
    return <FormArray>this.addCandidateForm.get('experience');
  }

  get touchPoints() {
    return this.addCandidateForm.get('touchPoints') as FormArray;
  }

  get documents() {
    return this.addCandidateForm.get('basicInfo.documents') as FormArray;
  }

  // Adding Arrays
  addEducation() {
    this.education.push(this.createEducation());
  }

  addExperience() {
    this.experience.push(this.createExperience());
  }

  addTouchPoints() {
    this.touchPoints.push(this.createTouchPoints());
  }

  adddocuments(name: string, url: string, size: number, isResume: boolean,) {
    this.documents.push(this.createDocument(name, url, size, isResume));
  }

  // Removeing Arrays
  removeEducation(index: any) {
    this.education.removeAt(index);
    if (this.education.length == 0)
      this.addEducation();
  }

  removeTouchPoints(index: any) {
    // console.log(this.touchPoints.value[index].contactOutcome);
    this.touchPoints.removeAt(index);
    if (this.touchPoints.length == 0)
      this.addTouchPoints();
  }

  removeExperience(index: any) {
    this.experience.removeAt(index);
    if (this.experience.length == 0)
      this.addExperience();
  }

  removeDocuments(index: any) {
    this.documents.removeAt(index);
  }

  removeEduAndExpAndContactAndDoc() {
    while (this.education.length != 1) {
      this.education.removeAt(0)
    }
    while (this.experience.length != 1) {
      this.experience.removeAt(0)
    }
    while (this.touchPoints.length != 1) {
      this.touchPoints.removeAt(0)
    }
    if (this.experience.value.length > 0 && this.experience.value[0].responsibilities) {
      while (this.experience.value[0].responsibilities.length != 0) {
        this.responsibilities(0).removeAt(0);
      }
    }
    if (this.documents) {
      while (this.documents.length != 0) {
        this.documents.removeAt(0)
      }
    }
    if (this.tags) {
      while (this.tags.length != 0) {
        this.tags.removeAt(0)
      }
    }
    if (this.industry) {
      while (this.industry.length != 0) {
        this.industry.removeAt(0)
      }
    }
    if (this.compNotes) {
      while (this.compNotes.length != 0) {
        this.compNotes.removeAt(0)
      }
    }
    if (this.seeking) {
      while (this.seeking.length != 0) {
        this.seeking.removeAt(0)
      }
    }
    if (this.possibleLocations) {
      while (this.possibleLocations.length != 0) {
        this.possibleLocations.removeAt(0)
      }
    }
  }

  fullyResetBioForm() {
    this.removeEduAndExpAndContactAndDoc();
    this.clearForm();

    this.BasicInfo = true;
    this.Education = false;
    this.Experience = false;
    this.Accommodation = false;

    this.arrayEducation = [];
    this.arrayExperience = [];
    this.arrayTouchPoints = [];
  }

  clearForm() {
    this.addCandidateForm.reset();

    this.addCandidateForm.patchValue({
      // step 1
      basicInfo: {
        candidateName: '',
        email: '',
        phone: '',
        address: '',
        linkedIn: '',
        source: '',
        status: '',
        quickSummary: '',
        isValidated: false,
        tags: [],
        industry: [],
        documents: []
      },

      // step 2
      education: [{
        GPA: '',
        currentlyEnrolled: false,
        degree: '',
        expectedGraduationDate: '',
        graduationDateFrom: '',
        graduationDateTo: '',
        major: '',
        minor: '',
        schoolName: ''
      }],

      // step 3
      experience: [{
        companyName: '',
        jobTitle: '',
        startDate: '',
        endDate: '',
        currentlyEmployed: '',
        address: '',
        responsibilities: [],
      }],

      // step 4
      salaryInfo: {
        currentSalary: '',
        currentTC: '',
        currentVC: '',
        desiredRangeLow: '',
        desiredRangeHigh: '',
        compNotes: []
      },

      // step 5
      logisticsInfo: {
        canRelocate: false,
        possibleLocations: [],
        canStart: '',
        isEmployed: false,
        seeking: [],
      },

      // step 6
      touchPoints: [{
        contactType: '',
        contactPurpose: '',
        contactOutcome: '',
        recruiterSummary: '',
        timeStamp: ''
      }]
    })
  }

  // ------------------------- Chips

  createChips(name: string) {
    return this.fb.group({
      name: name
    });
  }

  get tags() {
    return this.addCandidateForm.get('basicInfo.tags') as FormArray;
  }

  get industry() {
    return this.addCandidateForm.get('basicInfo.industry') as FormArray;
  }

  get compNotes() {
    return this.addCandidateForm.get('salaryInfo.compNotes') as FormArray;
  }

  get possibleLocations() {
    return this.addCandidateForm.get('logisticsInfo.possibleLocations') as FormArray;
  }

  get seeking() {
    return this.addCandidateForm.get('logisticsInfo.seeking') as FormArray;
  }


  get skills() {
    return this.addCandidateForm.get('logisticsInfo.skills') as FormArray;
  }

  responsibilities(index: number) {
    return <FormArray>this.experience.controls[index].get('responsibilities');
  }

  addtags(item: any) {
    if (typeof (item) === 'string') {
      this.tags.push(this.createChips(item));
      return;
    }
    item.map((i: any) => this.tags.push(this.createChips(i.name)));
  }

  addIndustry(item: any) {
    if (typeof (item) === 'string') {
      this.industry.push(this.createChips(item));
      return;
    }
    item.map((i: any) => this.industry.push(this.createChips(i.name)));
  }

  addCompNotes(item: any) {
    if (typeof (item) === 'string') {
      this.compNotes.push(this.createChips(item));
      return;
    }
    item.map((i: any) => this.compNotes.push(this.createChips(i.name)));
  }

  addPossibleLocations(item: any) {
    if (typeof (item) === 'string') {
      this.possibleLocations.push(this.createChips(item));
      return;
    }
    item.map((i: any) => this.possibleLocations.push(this.createChips(i.name)));
  }

  addSeeking(item: any) {
    if (typeof (item) === 'string') {
      this.seeking.push(this.createChips(item));
      return;
    }
    item.map((i: any) => this.seeking.push(this.createChips(i.name)));
  }


  addSkills(item: any) {
    if (typeof (item) === 'string') {
      this.skills.push(this.createChips(item));
      return;
    }
    item.map((i: any) => this.skills.push(this.createChips(i.name)));
  }

  addResponsibilities(item: any, index: number) {
    if (typeof (item) === 'string') {
      this.responsibilities(index).push(this.createChips(item));
      return;
    }
    item.map((i: any) => this.responsibilities(index).push(this.createChips(i.name)));
  }


  removeTags(index: any) {
    this.tags.removeAt(index);
  }

  removeIndustry(index: any) {
    this.industry.removeAt(index);
  }

  removeCompNotes(index: any) {
    this.compNotes.removeAt(index);
  }

  removeSeeking(index: any) {
    this.seeking.removeAt(index);
  }

  removeSkills(index: any) {
    this.skills.removeAt(index);
  }

  removePossibleLocations(index: any) {
    this.possibleLocations.removeAt(index);
  }

  removeResponsibilities(parentIndex: number, childIndex: number) {
    this.responsibilities(parentIndex).removeAt(childIndex);
  }


  alterArrayControls(condition: boolean, formGroupName: string, formControlName: string) {
    if (condition) {
      let form: FormGroup = <FormGroup>(this.addCandidateForm.get(formGroupName));
      form.removeControl(formControlName);
    }
    else {
      let form: FormGroup = <FormGroup>(this.addCandidateForm.get(formGroupName));
      form.addControl(formControlName, this.fb.array([]));
    }
  }

  alterResponsibilitiesControl(condition: boolean, index: number) {
    if (condition) {
      let form: FormGroup = <FormGroup>(this.experience.controls[index]);
      form.removeControl('responsibilities');
    }
    else {
      let form: FormGroup = <FormGroup>(this.experience.controls[index]);
      form.addControl('responsibilities', this.fb.array([]));
    }
  }

  disablingForm(form: any, formTitle: any) {
    if (formTitle == 'Update') {

    }
  }

  private currentDate = new Date();
  currentDay = this.currentDate.getDate() < 10
    ? '0' + this.currentDate.getDate()
    : this.currentDate.getDate();
  currentMonth = this.currentDate.getMonth() < 9
    ? '0' + (this.currentDate.getMonth() + 1)
    : (this.currentDate.getMonth() + 1);
  currentYear = this.currentDate.getFullYear();


}
