import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials'
})
export class NameInitialsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value && value.toUpperCase().split(' ').map((n:any) => n[0]).join(' ');
  }

}
