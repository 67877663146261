import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { FirstNamePipe } from './pipes/first-name.pipe';

@NgModule({
  declarations: [NameInitialsPipe, FirstNamePipe],
  imports: [
    CommonModule
  ],
  exports: [
    NameInitialsPipe,
    FirstNamePipe
  ]
})
export class SharedModule { }
