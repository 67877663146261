import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

export class DynamicTable implements OnInit {
  constructor() {

  }

  public ngOnInit(): void { }


  GenerateTable(params: any) {
    // after logic return data
    const candidateInfo = [];
    candidateInfo.push(["subID", "jobID", "posting.CompanyName", "posting.jobTitle", "posting.locations", "posting.relocate", "posting.reqQuals",
      "posting.prefQuals", "posting.phase", "posting.responsibilities", "posting.compHigh", "posting.compLow", "posting.industries",
      "posting.maxSubs", "posting.availableSubs", "posting.findersFee", "posting.payFrequenc", "posting.hireType", "posting.postingType",
      "sub.status", "sub.currentSubmissions", "cand.name", "cand.location", "cand.willingToRelocate", "cand.currentCompany",
      "cand.currentjobTitle", "cand.ed.schoolName", "cand.ed.degree", "cand.ed.major", "cand.ed.gradYear", "cand.industries",
      "cand.skillTags", "cand.salaryExpLow", "cand.salaryExpHigh", "cand.comNotes", "cand.availability", "cand.phase",
      "cand.status", "sub.recNote", "sub.dateConsidered", "sub.dateSubmitted", "sub.furthestPhase", "sub.rejectionReason",
      "sub.offer?", "sub.subScore_Overall", "sub.subFeedbackScore", " sub.hired?", "sub.paid?", "sub.findersFee"]);
    params.forEach((data:any) => {
      data['candidateMeta'].forEach((candidate:any) => {
        candidateInfo.push([candidate['submissionID'], candidate['submittedObject']['jobID'], data['postingCompany'],
        data['postingTitle'], " ", data['canRelocate'], " ", " ", " ", data['roleLevel'], " ", " ", data['industry'],
        data['maxSubmissions'], " ", data['findersFee'], " ", " ", " ", candidate['submittedObject']['status'], " ",
        candidate['submittedObject']['candidateName'], " ", " ", candidate['currentCompany'], candidate['currentTitle'],
          " ", " ", " ", " ", " ", " ", " ", " ", " ", " ",
        candidate['phase'], candidate['status'], " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " "])
      });
    });
    //Create a HTML Table element.
    var table = document.createElement("TABLE") as HTMLTableElement;
    table.style.border = "1"

    //Get the count of columns.
    var columnCount = candidateInfo[0].length;

    //Add the header row.
    var row = table.insertRow(-1);
    for (var i = 0; i < columnCount; i++) {
      var headerCell = document.createElement("TH") as HTMLTableElement;
      headerCell.innerHTML = candidateInfo[0][i];
      row.appendChild(headerCell);
    }

    //Add the data rows.
    for (var i = 1; i < candidateInfo.length; i++) {
      row = table.insertRow(-1);
      for (var j = 0; j < columnCount; j++) {
        var cell = row.insertCell(-1);
        cell.innerHTML = candidateInfo[i][j];
      }
    }
    return table
  }
}
